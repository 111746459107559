import Layout from "@/layout/index.vue";
// 我的门户
const homeSystemRouter = [
  {
    path: "/home",
    component: Layout,
    redirect: "",
    hidden: true,
    name: "home",
    meta: { title: "我的门户", icon: "" },
    children: [
      {
        path: "/advanceManage",
        component: () => import("@/views/adminViews/advanceManage/index.vue"),
        name: "advanceManage",
        meta: { title: "预支管理", icon: "" },
      },
    ],
  },
];
export default homeSystemRouter;

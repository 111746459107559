<template>
  <div class="tabel_content">
    <div style="height: 50px">
      <div class="header_title">
        <div class="title" v-if="editTabelData.tabelTitle.title">
          {{ editTabelData.tabelTitle.title }}
          <span class="tips" v-if="editTabelData.tabelTitle.recommend">
            <span class="tips_title">点击这里可以导入平台为你推荐： </span>
            <span class="tips_value" @click="handleRecommend">
              {{ editTabelData.tabelTitle.recommend }}
            </span>
          </span>
        </div>

        <div class="submit_time">
          <filterColumn
            v-if="editTabelData.showFilter"
            title="请选择列表中要展示的列"
            :headerData="editTabelData.tabelHeaders"
          />
          <!-- <div class="submit_time_label">
            {{ editTabelData.tabelTitle.label }}
          </div>
          <div class="submit_time_value">
            {{ editTabelData.tabelTitle.content }}
          </div>
          <el-button
            class="textbtn"
            link
            style="color: #06b0b2"
            @click="editTabelBtnClick"
          >
            {{ editTabelData.tabelTitle.btnText }}
          </el-button> -->
        </div>
      </div>
      <div style="background-color: #d8d8d8; width: 100%; height: 1px"></div>
    </div>

    <div class="tabel_container">
      <el-table
        :data="formData[editTabelData.tabelTitle.key]"
        style="
          width: 100%;
          height: 100%;
          font-family: Alibaba PuHuiTi 2;
          font-size: 12px;
          color: #666666;
          overflow: auto;
        "
        :height="editTabelData.tabelHeight"
        :header-cell-style="customStyle"
        :show-summary="editTabelData.showSummary"
        :summary-method="getSummaries"
        :class="{ no_padding: editTabelData.isPadding }"
      >
        <template
          v-for="(item, index) in editTabelData.tabelHeaders"
          :key="item.prop"
        >
          <el-table-column
            v-if="item.prop === 'index' && item.checked"
            label="序号"
            type="index"
            width="60"
            align="center"
          />
          <el-table-column
            label="操作"
            v-else-if="item.prop === 'operation' && item.checked"
            width="55"
            align="left"
            :fixed="item.fixed"
          >
            <template #default="scope">
              <el-button
                :disabled="editTabelData.tabelList.length === 1"
                class="textbtn"
                link
                style="color: #ff4545"
                @click="handleDelete(scope.$index, scope.row)"
              >
                -
              </el-button>
              <el-button
                v-if="editTabelData.tabelList.length == scope.$index + 1"
                class="textbtn"
                link
                style="color: #06b0b2"
                @click="handleAdd(scope.$index)"
              >
                +
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            :label="item.label"
            show-overflow-tooltip
            :width="item.width"
            :prop="item.prop"
            :summary-method="item.summaryMethod"
            :fixed="item.fixed"
            v-if="
              item.prop !== 'operation' && item.prop !== 'index' && item.checked
            "
          >
            <template #header>
              <!-- 显示必填标识 -->
              <span :class="isReqquire ? 'active' : ''" class="tabel_header">
                <span class="require" v-if="item.requireAble">*</span
                ><span>{{ item.label }}</span
                ><span v-if="item.requireAble && isReqquire">不能为空</span>
                &nbsp;&nbsp;
                <el-tooltip
                  v-if="item.tooltip"
                  :content="item.tooltip"
                  popper-class="tooltip_text"
                  effect="light"
                  placement="top-start"
                >
                  <el-icon :size="16" color="#ccc"><Warning /></el-icon>
                </el-tooltip>
              </span>
            </template>
            <template #default="scope">
              <div class="tabel_cell">
                <el-select
                  v-if="item.type === 'select'"
                  v-model="scope.row[item.prop]"
                  size="default"
                  :disabled="item.disabled"
                  style="width: 100%"
                  clearable
                  filterable
                  :placeholder="'请选择' + item.titleName"
                >
                  <el-option
                    v-for="opt in item.data"
                    :key="opt.value"
                    :value="opt.value"
                    :label="opt.label"
                  >
                  </el-option>
                </el-select>
                <el-autocomplete
                  v-else-if="item.type === 'autocomplete'"
                  v-model.trim="scope.row[item.prop]"
                  :fetch-suggestions="
                    (queryString, cb) =>
                      querySearch(queryString, cb, item.prop, scope)
                  "
                  placement="bottom"
                  placeholder="请输入"
                  @select="(val) => autocompleteSelect(val, scope, item.prop)"
                  ><template #default="{ item }">
                    <div class="value">{{ item }}</div>
                    <!-- <span class="link">{{ item.link }}</span> -->
                  </template></el-autocomplete
                >

                <el-input
                  v-else-if="item.type === 'text'"
                  :type="item.inputType"
                  v-model="scope.row[item.prop]"
                  style="width: 100%"
                  :min="0"
                  @blur="validateInput(item, scope.row[item.prop])"
                  @input="(val) => numberInput(val, item, scope)"
                />

                <el-popover
                  ref="tabelPopover"
                  placement="left"
                  :width="720"
                  trigger="click"
                  popper-class="select_tabel_popover"
                  v-else-if="item.type === 'selectTabel'"
                >
                  <template #reference>
                    <el-input
                      v-model="scope.row[item.prop]"
                      :suffix-icon="ArrowDown"
                      class="focus"
                      @input="
                        (val) => filterChange(val, item.prop, scope.$index)
                      "
                      @focus="handleFocus"
                      @keydown.enter.stop
                    ></el-input>
                  </template>
                  <div
                    class="btn"
                    v-if="item.isAdd"
                    @click="selectTabelAdd(item.prop)"
                  >
                    <el-image
                      style="
                        width: 16px;
                        line-height: 16px;
                        margin-left: 8px;
                        cursor: pointer;
                      "
                      :src="require('../../assets/image/road2.png')"
                    ></el-image>
                    <span>新增物料</span>
                  </div>
                  <globalTabel
                    :params="item.params"
                    :tabelProps="item.tabelProps"
                    @currentChange="(val) => currentChange(val, item)"
                    @rowClick="(row) => rowClick(row, scope.$index)"
                  />
                </el-popover>

                <div v-else-if="item.type === 'uploadFile'">
                  <el-upload
                    v-if="scope.row[item.prop].length === 0"
                    :disabled="item.disabled"
                    v-model:file-list="scope.row[item.prop]"
                    action="#"
                    :auto-upload="false"
                    :accept="item.accept"
                    :limit="item.limit"
                    :show-file-list="false"
                    :on-exceed="handleExceed"
                    :before-upload="setListForm"
                    :on-change="
                      (param) => handleChange(param, scope, item.prop)
                    "
                  >
                    <el-button type="primary" link>{{
                      item.btnText
                    }}</el-button>
                  </el-upload>
                  <el-button
                    v-else
                    type="primary"
                    link
                    @click="preview(scope.row[item.prop])"
                    >预览</el-button
                  >
                </div>

                <el-cascader
                  ref="cascader"
                  v-else-if="item.type === 'cascader'"
                  v-model="scope.row[item.prop]"
                  :change-on-select="true"
                  :options="item.data"
                  :props="item.props"
                  :disabled="item.disabled"
                  style="width: 100%"
                  clearable
                  filterable
                  placement="bottom-end"
                  @change="(val) => cascaderChange(val, scope.$index)"
                />

                <el-image
                  v-if="item.type === 'img' && scope.row[item.prop]"
                  preview-teleported
                  :src="scope.row[item.prop]"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :preview-src-list="[scope.row[item.prop]]"
                  :initial-index="4"
                  @click.stop
                  style="
                    width: 32px;
                    height: 32px;
                    border-radius: 2px;
                    display: block;
                    margin: 0 auto;
                  "
                  z-index="1111111"
                  fit="cover"
                />

                <!-- <img
                  v-else-if="item.type === 'img' && scope.row[item.prop]"
                  :src="scope.row[item.prop]"
                  alt=""
                  style="
                    width: 24px;
                    height: 24px;
                    border-radius: 2px;
                    display: block;
                    margin: 0 auto;
                  "
                /> -->
                <el-button
                  v-else-if="item.type === 'textBtn'"
                  type="primary"
                  link
                  color="#06B0B2"
                  @click.stop="editTabelBtnClick(scope.row, item.label)"
                  >{{ item.btnText }}</el-button
                >
                <span v-else-if="item.type === 'time'">{{
                  timestampToDate(scope.row[item.prop])
                }}</span>
                <span v-else-if="item.type === 'allDate'">{{
                  getYearMonthTime(scope.row[item.prop])
                }}</span>
                <span class="span_cell" v-else-if="item.type === ''">{{
                  scope.row[item.prop]
                }}</span>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>
<script setup>
import filterColumn from "@/components/globalTabel/filterColumn.vue";
import { timestampToDate, getYearMonthTime } from "@/uitls/global";
import { imgUpLoadApi } from "@/api/base";
import { Warning, ArrowDown } from "@element-plus/icons-vue";
import { onMounted, defineEmits } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref } from "vue";

const customStyle = {
  //表头样式
  background: "#F5F5F5",
  border: "none",
  fontWeight: 400,
};

const isReqquire = ref(false);

const emits = defineEmits([
  "updateFormData",
  "uploadOcr",
  "selectTabelRowClick",
  "filterChange",
  "cascaderChange",
  "editTabelBtnClick",
  "selectTabelCurrentChange",
  "handleFocus",
  "selectTabelAdd",
  "handleRecommend",
]);

const props = defineProps({
  editTabelData: Object,
  formData: {
    type: Object,
    default: {},
  },
});
const tabelPopover = ref(null);
const cascader = ref(null);

onMounted(() => {});

// 初始化数据
const init = () => {
  //   editTabelData.tabelList有数据不用初始化，没有数据就把每一项的值设为"
  if (props.editTabelData.tabelList.length === 0) {
    let obj = {};
    props.editTabelData.tabelHeaders.forEach((item) => {
      if (item.prop === "operation" || item.prop === "index") {
        return;
      } else if (item.type === "uploadFile" || item.type === "cascader") {
        obj[item.prop] = [];
      } else {
        obj[item.prop] = "";
      }
    });
    props.editTabelData.tabelList.push(obj);
  }
  props.formData[props.editTabelData.tabelTitle.key] =
    props.editTabelData.tabelList;
};
init();

const handleRecommend = () => {
  emits("handleRecommend");
};

const selectTabelAdd = (name) => {
  emits("selectTabelAdd", name);
};

const handleFocus = () => {
  emits("handleFocus");
};

const autocompleteSelect = (val, scope, prop) => {
  props.editTabelData.tabelList[scope.$index][prop] = val;
};

const currentChange = (current, item) => {
  emits("selectTabelCurrentChange", current, item);
};

const getSummaries = (param) => {
  const { columns, data } = param;
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = "合计";
      return;
    }
    const values = data.map((item) => Number(item[column.property]));
    // 只对amount这一列进行总计核算。
    if (
      column.property === "contractPrice" ||
      column.property === "logisticsPrice" ||
      column.property === "returnPrice" ||
      column.property === "balancePrice" ||
      column.property === "advancePaidPrice" ||
      column.property === "payablePrice"
    ) {
      if (!values.every((value) => isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index];
      } else {
        sums[index] = "---";
      }
    }
  });
  return sums;
};

const editTabelBtnClick = (row) => {
  emits("editTabelBtnClick", row);
};

const cascaderChange = (val, index) => {
  emits(
    "cascaderChange",
    cascader.value[0].cascaderPanelRef.checkedNodes[0].text,
    index
  );
};

const filterChange = (val, prop, index) => {
  emits("filterChange", val, prop, index);
};
const rowClick = (row, index) => {
  tabelPopover.value[0].hide();
  emits("selectTabelRowClick", row, index);
};

// 上传
const handleChange = async (param, scope, prop) => {
  const fileFormData = new FormData();
  fileFormData.append("multipartFile", param.raw);
  const res = await imgUpLoadApi(fileFormData);
  if (res.status === 200) {
    // props.editTabelData.tabelList[scope.$index][prop] = [
    //   { url: res.data.filePath, name: res.data.fileName },
    // ];
    props.editTabelData.tabelList[scope.$index][prop] = res.data.filePath;
    emits("uploadOcr", res.data.filePath, scope.$index);
  }
};

const handleExceed = (files, fileList) => {
  ElMessage.error("上传文件数量不能超过" + 1 + "个");
};

// 文件预览
const preview = (val) => {
  window.open(val);
};

const numberInput = (val, item, scope) => {
  console.log(val, item, scope);

  if (item.inputType === "number") {
    console.log(11111);

    if (val.includes("-")) {
      ElMessage.error("请输入正确的数字");
      props.editTabelData.tabelList[scope.$index][item.prop] = 0;
    }
  }
};

// 校验输入
const validateInput = (item, value) => {
  if (item.requireAble && value === "") {
    isReqquire.value = true;
    item.label;
  } else {
    isReqquire.value = false;
  }
};

const querySearch = (queryString, cb, prop, scope) => {
  const data = props.editTabelData.tabelHeaders.find(
    (item) => item.prop === prop
  ).data;
  const result = data.filter((item) => item.includes(queryString));
  cb(result);
};

// 新增
const handleAdd = (index) => {
  let obj = {};
  props.editTabelData.tabelHeaders.forEach((item) => {
    if (item.prop === "operation" || item.prop === "index") {
      return;
    } else if (item.type === "uploadFile") {
      obj[item.prop] = [];
    } else {
      obj[item.prop] = "";
    }
  });
  props.editTabelData.tabelList.splice(index + 1, 0, obj);
};

// 删除
const handleDelete = (index, row) => {
  ElMessageBox.confirm("确定删除该条数据吗？", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      if (!row.id) {
        props.editTabelData.tabelList.splice(index, 1);
        ElMessage({
          type: "success",
          message: "删除成功",
        });
      } else {
        const ids = [row.id];
        const res = await deleteByIds(ids);
        if (res.status === 200) {
          ElMessage({
            type: "success",
            message: "删除成功",
          });
        }
      }
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "已取消",
      });
    });
};
</script>
<style lang="scss" scoped>
.tabel_content {
  box-sizing: border-box;
  // padding: 2% 0;
  // padding-top: 15px;
  .header_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    padding-right: 20px;
    .title {
      padding-left: 10px;
      // margin-bottom: 15px;
      margin-left: 20px;
      font-family: Alibaba PuHuiTi 2;
      font-size: 14px;
      border-left: 2px solid #06b0b2;
    }
    .submit_time {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #9e9e9e;
      .submit_time_label {
        margin-right: 10px;
      }
      .textbtn {
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }

  .textbtn {
    font-size: 25px;
    width: 10px;
  }

  .require {
    color: #ff4545;
    font-size: 16px;
    margin-right: 5px;
  }

  .active {
    color: #ff4545;
  }

  .tabel_container {
    height: 100%;
    padding: 0 20px;
    padding-top: 10px;
  }
}

.tabel_header {
  display: flex;
  align-items: center;
}

.tabel_cell {
  display: flex;
  align-items: center;
}

:deep(.el-tabel) {
  // 高度撑满盒子
  height: 100%;
}

.no_padding {
  :deep(.el-table__row .cell) {
    padding: 0;
    padding-left: 10px;
  }

  .span_cell {
    padding-left: 10px;
  }
}

:deep(.el-table .cell) {
  padding: 0 2px !important;
  // padding-left: 10px;
}

.focus {
  :deep(.el-input__wrapper.is-focus .el-icon) {
    color: #06b0b2;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #06b0b2;
  cursor: pointer;
  span {
    margin-left: 4px;
  }
}

.tips {
  margin-left: 20px;
  font-size: 12px;
  color: #9e9e9e;

  .tips_value {
    color: #06b0b2;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="global_table">
    <!-- 封装一个全局el-tabel组件 -->
    <div class="handel_header">
      <div class="search">
        <!-- <el-input
          v-model="params.searchValue"
          style="width: 240px"
          placeholder="Please Input"
          :suffix-icon="Search"
        /> -->
        <el-form ref="ruleFormRef" :model="params" label-position="left" inline>
          <FormItem
            :formTitles="tabelProps.serchTitles"
            :formData="params"
            :searchLength="searchLength"
            :grid="2"
            @handelSearch="handelSearch"
            @selectChange="selectChange"
            @handelDateChange="handelDateChange"
            @cascaderSelectChange="cascaderSelectChange"
            @handelTreeChange="handelTreeChange"
          ></FormItem>
        </el-form>

        <filterColumn
          v-if="!tabelProps.offFilter"
          title="请选择列表中要展示的列"
          :headerData="tabelProps.tabelHeaders"
        />
      </div>

      <div class="btn">
        <span
          v-for="(item, index) in tabelProps.handelBtnText"
          :key="index"
          style="margin-left: 10px"
        >
          <el-dropdown
            v-if="item.type === 'dropdown'"
            trigger="click"
            @command="handleCommand"
            placement="bottom-end"
          >
            <el-button type="primary" style="font-size: 12px">
              {{ item.label }}
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="gender in item.data"
                  :key="gender.value"
                  :command="gender.value"
                  :icon="gender.icon"
                  style="padding-left: 30px; font-size: 12px"
                  divided
                  >&nbsp;{{ gender.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            v-else
            type="primary"
            :icon="item.icon"
            :disabled="item.disabled"
            style="font-size: 12px"
            @click="handelBtnClick(item.label)"
          >
            {{ item.label }}
          </el-button>
        </span>
      </div>
    </div>

    <div class="content">
      <el-table
        ref="globalTabelRef"
        :data="tabelProps.tabelData"
        border
        style="
          font-family: Alibaba PuHuiTi 2;
          font-size: 12px;
          color: #666666;
          margin-bottom: 50px;
        "
        :height="
          tabelProps.height
            ? tabelProps.height
            : tabelProps.isWorkFlow
            ? 'calc(100vh - 445px)'
            : 'calc(100vh - 324px)'
        "
        row-key="id"
        v-loading="tabelProps.loading"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :header-cell-style="customStyle"
        @row-click="handleRowClick"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="tabelProps.isSelection"
          :selectable="tabelProps.isDisabled"
          type="selection"
          width="55"
        />
        <template v-for="item in tabelProps.tabelHeaders" :key="item.prop">
          <el-table-column
            v-if="item.prop === 'treeIndex' && item.checked"
            label="序号"
            width="100"
          >
            <template #default="{ row }">
              <span v-if="row.isIndex">{{ row.parentIndex }}</span>
              <span v-else>{{ secondIndex(row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="item.prop === 'index' && item.checked"
            label="序号"
            type="index"
            width="55"
          />
          <el-table-column
            :label="item.label"
            v-else-if="item.prop === 'operation' && item.checked"
            width="90"
          >
            <template #default="scope">
              <el-button
                v-for="(gender, index) in item.data"
                :key="index"
                type="primary"
                link
                :style="{ color: gender.color ? gender.color : '#06B0B2' }"
                @click.stop="handleClick(scope.row, gender.label)"
                >{{ gender.label }}</el-button
              >
            </template>
          </el-table-column>

          <el-table-column
            :label="item.label"
            :show-overflow-tooltip="item.type !== 'dropdown'"
            v-if="
              item.prop !== 'operation' &&
              item.prop !== 'index' &&
              item.prop !== 'treeIndex' &&
              item.checked
            "
            :sortable="item.sortable ? 'custom' : false"
            :width="item.width"
            :prop="item.prop"
            :fixed="item.fixed"
          >
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <el-image
                  v-if="item.type === 'img' && scope.row[item.prop]"
                  preview-teleported
                  :src="scope.row[item.prop]"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :preview-src-list="[scope.row[item.prop]]"
                  :initial-index="4"
                  @click.stop
                  style="
                    width: 32px;
                    height: 32px;
                    border-radius: 2px;
                    display: block;
                    margin: 0 auto;
                  "
                  z-index="1111111"
                  fit="cover"
                />
                <!-- <img
                  v-if="item.type === 'img111'"
                  :src="scope.row[item.prop]"
                  alt=""
                  style="
                    width: 24px;
                    height: 24px;
                    border-radius: 2px;
                    display: block;
                    margin: 0 auto;
                  "
                /> -->
                <el-button
                  v-else-if="item.type === 'textBtn'"
                  type="primary"
                  link
                  color="#06B0B2"
                  @click.stop="handleClick(scope.row, item.label)"
                  >{{ item.btnText }}</el-button
                >

                <el-button
                  v-else-if="item.type === 'textBtnList'"
                  v-for="(gender, i) in item.data"
                  :key="i"
                  type="primary"
                  link
                  color="#06B0B2"
                  @click.stop="
                    handleBtnListClick(scope.row, item.label, gender)
                  "
                  >{{ gender }}</el-button
                >

                <span
                  v-else-if="
                    item.type === 'spanList' &&
                    (!scope.row.isNull || item.prop == 'showBtn')
                  "
                  v-for="(gender, num) in item.data"
                  :key="num"
                  :style="{
                    color: gender.color ? gender.color : '#06B0B2',
                    marginRight: '8px',
                    cursor: 'pointer',
                  }"
                  @click.stop="
                    handleBtnListClick(scope.row, item.label, gender)
                  "
                >
                  {{ gender }}
                </span>

                <span
                  v-else-if="item.type === 'status'"
                  :style="{ color: scope.row.color }"
                  >{{ scope.row[item.prop] }}</span
                >

                <span v-else-if="item.type === 'time'">{{
                  timestampToDate(scope.row[item.prop])
                }}</span>
                <span v-else-if="item.type === 'allDate'">{{
                  getYearMonthTime(scope.row[item.prop])
                }}</span>
                <div class="cell_list" v-else-if="item.type === 'cellList'">
                  <!-- <span
                    v-for="(cell, index) in scope.row[item.prop]"
                    :key="index"
                    >{{ timestampToDate(cell.paymentDate) }}</span
                  > -->
                  <span
                    v-for="(cell, index) in scope.row[item.prop]"
                    :key="index"
                    class="cell_item"
                    type="primary"
                    link
                    :style="{ color: item.color ? item.color : '#06B0B2' }"
                    @click.stop="handelCellBtnClick(scope.row, cell.id)"
                  >
                    <!-- {{
                      item.isDate
                        ? timestampToDate(cell[item.viewField])
                        : cell[item.viewField]
                    }} -->
                    <span
                      v-for="gender in item.viewField"
                      style="margin-right: 10px"
                    >
                      {{
                        gender.isDate
                          ? timestampToDate(cell[gender.prop])
                          : cell[gender.prop]
                      }}{{ gender.unit }}
                    </span>
                  </span>
                </div>
                <div
                  class="a"
                  v-else-if="item.type === 'dropdown' && scope.row[item.prop]"
                  @click.stop
                >
                  <el-dropdown popper-class="cell_dropdown">
                    <span class="el-dropdown-link">
                      {{
                        timestampToDate(
                          scope.row[item.prop][0][item.viewField[0].prop]
                        ) +
                        " " +
                        scope.row[item.prop][0][item.viewField[1].prop] +
                        item.viewField[1].unit
                      }}
                      <el-icon class="el-icon--right">
                        <arrow-down />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-for="(cell, num) in scope.row[item.prop]"
                          :key="num"
                          @click.stop="
                            handelCellBtnClick(
                              scope.row,
                              cell.id,
                              item.prop,
                              cell
                            )
                          "
                        >
                          <span
                            v-for="gender in item.viewField"
                            style="margin-right: 10px"
                          >
                            {{
                              gender.isDate
                                ? timestampToDate(cell[gender.prop])
                                : cell[gender.prop]
                            }}{{ gender.unit }}
                          </span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
  <div class="pagination" v-if="params.total">
    <span class="total">共{{ params.total }}条数据</span>
    <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      background
      layout="prev, pager, next"
      :total="params.total"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script setup>
import { timestampToDate, getYearMonthTime } from "@/uitls/global";
import filterColumn from "./filterColumn";
import {
  Search,
  Plus,
  ArrowDown,
  Edit,
  CreditCard,
} from "@element-plus/icons-vue";
import { ref, defineProps, defineEmits } from "vue";

const emits = defineEmits([
  "rowClick",
  "rowBtnClick",
  "currentChange",
  "handleBtnClick",
  "handelSearch",
  "selectChange",
  "handleCommand",
  "sortChange",
  "handelDateChange",
  "handelCellBtnClick",
  "handleSelectionChange",
  "handleBtnListClick",
  "handelTreeChange",
  "cascaderSelectChange",
]);

const { params, tabelProps } = defineProps({
  tabelProps: {
    type: Object,
    default: () => ({}),
  },

  params: {
    type: Object,
    default: () => ({}),
  },
});

//表头样式
const customStyle = {
  background: "#F5F5F5",
  border: "none",
  fontWeight: 400,
};
const globalTabelRef = ref(null);
const searchLength = ref(0);

const cascaderSelectChange = (val, name, value) => {
  emits("cascaderSelectChange", val, name, value, "tabel");
};

const handleBtnListClick = (row, label, btnText) => {
  emits("handleBtnListClick", row, label, btnText);
};

const handelTreeChange = (val, treeRef, formData) => {
  emits("handelTreeChange", val, treeRef, formData);
};

const handleDisable = (row) => {
  // tabelProps.isDisable(row);
  console.log(tabelProps.isDisable);
};

const handleSelectionChange = (checks) => {
  const ids = checks.map((item) => item.id);
  emits("handleSelectionChange", ids, checks);
};

const handelCellBtnClick = (row, id, prop, cell) => {
  emits("handelCellBtnClick", row, id, prop, cell);
};

const handelDateChange = (val) => {
  emits("handelDateChange", val);
};
const handleCommand = async (command) => {
  emits("handleCommand", command);
};

const handleSortChange = (prop) => {
  emits("sortChange", prop);
};

// 树形表格序号
const secondIndex = (row) => {
  return row.menuSequence;
};

const selectChange = (val, item) => {
  emits("selectChange", val, item, "tabel");
};

const handelSearch = (val) => {
  emits("handelSearch", val);
};

// 行内按钮点击
const handleClick = (row, label) => {
  emits("rowBtnClick", row, label);
};

// 监听表格行点击
const handleRowClick = (row, column, event) => {
  emits("rowClick", row);
};

// 点击按钮
const handelBtnClick = (label) => {
  emits("handleBtnClick", label);
};

// 监听分页变化
const handleCurrentChange = (currentPage) => {
  emits("currentChange", currentPage);
};

// 清空排序
const clearSort = () => {
  globalTabelRef.value.clearSort();
};

// 向外暴露清空排序方法
defineExpose({
  clearSort,
});
</script>
<style lang="scss" scoped>
.global_table {
  width: 100%;
  .handel_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .search {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }

  .content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}
.pagination {
  position: absolute;
  bottom: 10px;
  width: 99%;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-item-box {
  display: flex !important;
  flex-wrap: nowrap !important;
  padding: 0 !important;
  border: none !important;

  :deep(.el-form-item__label) {
    padding: 0;
  }
  :deep(.el-input__wrapper, ) {
    // width: 200px !important;
    max-width: 200px !important;
    height: 34px !important;
  }

  :deep(.el-select__wrapper) {
    // max-width: 120px !important;
    min-width: 150px !important;
    height: 34px !important;
  }

  // 小于1920px时，调整样式
  @media screen and (max-width: 1920px) {
    :deep(.el-input__wrapper, ) {
      // width: 200px !important;
      max-width: 130px !important;
      height: 34px !important;
    }
    :deep(.el-select__wrapper) {
      // max-width: 120px !important;
      min-width: 130px !important;
    }
  }

  @media screen and (max-width: 1920px) {
    :deep(.el-input__wrapper, ) {
      // width: 200px !important;
      max-width: 130px !important;
      height: 34px !important;
    }
    :deep(.el-select__wrapper) {
      // max-width: 120px !important;
      min-width: 130px !important;
      height: 34px !important;
    }
  }

  :deep(.el-form-item) {
    margin-right: 12px;
    margin-bottom: 0 !important;
  }
}
//原本样式的图标点击后会有90°的旋转，此样式把旋转取消。//树形结构样式
:deep(.el-table .el-table__expand-icon--expanded) {
  transform: rotate(0deg);
}

:deep(.el-table .cell) {
  display: flex;
  align-items: center;
}

//有子节点 且未展开
.el-table :deep(.el-table__expand-icon) {
  .el-icon {
    font-size: 24px;

    svg {
      display: none;
    }
  }

  .el-icon:before {
    background: url("../../assets/image/road2.png") no-repeat center;
    background-size: 12px 12px !important;
    //原本content是一个图标，置为空
    margin-right: 10px;
    content: "";
    display: block;
    width: 35px;
    height: 19px;
    margin-bottom: 12px;
  }
}

//有子节点 且已展开
:deep(.el-table .el-table__expand-icon--expanded) {
  .el-icon:before {
    background: url("../../assets/image/road1.png") no-repeat center !important;
    background-size: 12px 12px !important;
    margin-right: 10px;
    content: "";
    display: block;
    width: 35px;
    height: 19px;
  }
}
:deep(.el-dropdown-menu__item) {
  width: 170px !important;
}

.cell_list {
  display: flex;
  flex-direction: column;
  align-items: left;
}

:deep(.cell_item) {
  margin-left: 0 !important;
  margin-bottom: 5px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.el-dropdown-link {
  font-size: 12px;
  color: #06b0b2;
}

.el-tooltip__trigger:focus {
  outline: none; // unset 这个也行
}

// :deep(.el-image-viewer__wrapper) {
//   z-index: 9999 !important;
// }
</style>

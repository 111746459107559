<template>
  <div class="layout_header">
    <div class="logo">
      <img
        src="@/assets/logo.png"
        alt=""
        style="width: 32px; height: 32px; border-radius: 16px"
      />
      <span class="title">
        <el-dropdown
          ref="dropdown"
          trigger="contextmenu"
          placement="bottom-end"
          popper-class="header_popper"
        >
          <span class="el-dropdown-link" @click="showDropdown">
            {{ tenantName
            }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in tenantList"
                :command="index"
                :divided="index !== 0"
                @click="handleSelectTenant(item)"
              >
                <el-avatar :src="item.logoUrl" width="20px" />
                <span style="margin-left: 8px">{{ item.tenantName }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </span>
    </div>
    <div class="rightMenu">
      <el-avatar
        v-if="avatarUrl"
        shape="square"
        :size="32"
        fit="fill"
        :src="avatarUrl"
      />

      <div v-else class="avatar">
        {{ defaultAvatar }}
      </div>

      <el-dropdown
        trigger="click"
        @command="handleCommand"
        placement="bottom-end"
        popper-class="header_popper"
      >
        <span class="el-dropdown-link">
          {{ username }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu style="width: 200px">
            <el-dropdown-item
              :icon="User"
              command="a"
              style="padding-left: 30px"
            >
              &nbsp;个人中心</el-dropdown-item
            >
            <el-dropdown-item
              :icon="Plus"
              command="b"
              divided
              style="padding-left: 30px"
            >
              &nbsp;加入团队</el-dropdown-item
            >
            <el-dropdown-item
              :icon="DocumentAdd"
              command="c"
              divided
              style="padding-left: 30px"
            >
              &nbsp;创办团队</el-dropdown-item
            >
            <el-dropdown-item
              :icon="Lock"
              command="d"
              divided
              style="padding-left: 30px"
            >
              &nbsp;修改密码</el-dropdown-item
            >
            <el-dropdown-item
              :icon="User"
              command="e"
              divided
              style="padding-left: 30px"
            >
              &nbsp;注销账号</el-dropdown-item
            >
            <el-dropdown-item
              :icon="HomeFilled"
              command="f"
              divided
              style="padding-left: 30px"
            >
              &nbsp;回到首页</el-dropdown-item
            >
            <el-dropdown-item
              :icon="SwitchButton"
              command="g"
              divided
              style="padding-left: 30px"
            >
              &nbsp;退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <selectDialog
      v-if="showSelectDialog"
      :seletDialogProp="seletDialogProp"
      @closeDialog="showSelectDialog = false"
    />
    <editPassword
      v-if="showEditPassword"
      @closeDialog="showEditPassword = false"
    />
  </div>
</template>

<script setup>
import { HomeFilled, SwitchButton } from "@element-plus/icons-vue";
import { getuserInfoApi } from "@/api/login";
import { ElMessage } from "element-plus";
import { getSysTenantByUserIdApi, switchTenantsApi } from "@/api/home";
import editPassword from "@/views/login/component/editPassword.vue";
import selectDialog from "@/views/home/component/selectDialog.vue";
import { logoutApi } from "@/api/login";
import Cookies from "@/uitls/cookie";
import {
  ArrowDown,
  User,
  Plus,
  DocumentAdd,
  Lock,
} from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const showSelectDialog = ref(false);
const showEditPassword = ref(false);
const avatarUrl = ref("");
const seletDialogProp = ref({ type: "header" });

const defaultAvatar = ref("");
const username = ref("您的姓名");
const tenantName = ref("您的团队名称");
const dropdown = ref(null);
const tenantList = ref([]);

const handleCommand = async (command) => {
  switch (command) {
    case "a":
      router.push("/basicInfo");
      break;
    case "b":
      showSelectDialog.value = true;
      break;
    case "c":
      router.push("/createTeam");
      console.log("创办企业");
      break;
    case "d":
      showEditPassword.value = true;
      break;
    case "e":
      console.log("注销账号");
      break;
    case "f":
      localStorage.setItem("activeMenu", "");
      localStorage.setItem(
        "activeMenuId",
        JSON.parse(localStorage.getItem("menuTree"))[0].id
      );
      window.location.href = "/";
      break;
    case "g":
      // 清除缓存
      const res = await logoutApi();
      if (res.status === 200) {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove("token");
        // 跳转登录页
        router.push("/login");
        console.log("退出登录");
      }

      break;
    default:
      break;
  }
};

onMounted(() => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    avatarUrl.value = user.avatar;
    username.value = user.nickName ? user.nickName : user.username;
    defaultAvatar.value = username.value.slice(username.value.length - 1);
    tenantName.value = user.tenantName || "暂无团队";
  }
});

const handleSelectTenant = async (item) => {
  const param = {
    tenantCode: item.tenantCode,
  };
  const res = await switchTenantsApi(param);
  if (res.status === 200) {
    tenantName.value = item.tenantName;
    localStorage.setItem("menuTree", JSON.stringify(res.data.menuTree));
    const response = await getuserInfoApi();
    if (response.status === 200) {
      localStorage.removeItem("activeMenu");
      localStorage.removeItem("activeMenuId");
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    ElMessage.success("切换成功");
    // window.location.reload();
    // router.push("/");
    window.location.href = "/";
  }
};

const showDropdown = () => {
  if (!dropdown.value) return;
  dropdown.value.handleOpen();
  getSysTenantByUserId();
};

const getSysTenantByUserId = async () => {
  const res = await getSysTenantByUserIdApi();
  if (res.status === 200) {
    tenantList.value = res.data;
  }
};
</script>

<style lang="scss" scoped>
:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}

.layout_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 99%;
  padding: 0 16px;
  .logo {
    display: flex;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      padding-left: 8px;
      font-size: 18px;
      color: #333333;
    }
  }

  .rightMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;

    .el-avatar {
      margin-right: 10px;
    }
  }
}

.avatar {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #06b0b2;
  color: #06b0b2;
  margin-right: 10px;
}
</style>

import axios from "axios";
import Cookie from "@/uitls/cookie";
import { ElMessageBox, ElMessage } from "element-plus";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

console.log("当前环境：" + process.env.NODE_ENV);
http.interceptors.request.use(function (config) {
  const token = Cookie.get("token");
  if (token) {
    config.headers.Satoken = `${token}`;
  }
  return config;
});
http.interceptors.response.use(
  (res) => {
    if (
      res.request.responseURL.includes("printReimbursementOrder") ||
      res.request.responseURL.includes("printAdvanceOrder")
    ) {
      return res;
    }
    if (
      res.data.status === 10015 ||
      res.data.status === 11014 ||
      res.data.status === 11012
    ) {
      ElMessage.error(res.data.message || "登录失效，请重新登录");
      router.push("/login");
      return res.data;
    }
    if (res.status !== 200 || res.data.status !== 200) {
      ElMessage.error(res.data.message || "网络错误");

      return res.data;
    }
    return res.data;
  },
  (error) => {
    ElMessage.error("请求错误，请稍后再试");
    return Promise.reject(error);
  }
);

export default http;

import Layout from "@/layout/index.vue";
// 合作伙伴系统
const partnerManageRouter = [
  {
    path: "/partnerManage",
    component: Layout,
    redirect: "partnerManage",
    hidden: true,
    name: "partnerManage",
    meta: { title: "合作伙伴系统", icon: "" },
    children: [
      {
        path: "/partnerFiles",
        component: () => import("@/views/partnerManage/partnerFiles/index.vue"),
        name: "partnerFiles",
        meta: { title: "伙伴档案", icon: "" },
      },
      {
        path: "/annualEvaluation",
        component: () => import("@/views/partnerManage/annualEvaluation/index.vue"),
        name: "annualEvaluation",
        meta: { title: "合作评价", icon: "" },
      },
      {
        path: "/partnerInfo",
        component: () => import("@/views/partnerManage/partnerFiles/partnerInfo.vue"),
        name: "partnerInfo",
        meta: { title: "伙伴详情页", icon: "" },
      },
    ],
  },
];
export default partnerManageRouter;

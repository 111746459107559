<template>
  <div
    class="form-item-box"
    :style="`grid-template-columns: repeat(${grid}, 1fr); max-height: ${
      grid == 2 ? '225px' : 'calc(100vh - 130px)'
    }; max-height: ${formHeight} !important; border-bottom: ${
      grid > 1 ? '1px solid #d8d8d8' : 'none'
    }; 
    padding: ${formTitles.length < 3 ? '12px 20px' : '12px 20px'};
    grid-gap: ${grid == 1 ? '12px !important' : '12px'};`"
  >
    <slot />
    <template v-for="(item, index) in formTitles">
      <!-- slotFTA 类型 -->
      <slot
        v-if="item.fontendType == 'slotFTA'"
        :name="item.fieldName + '_FTA'"
        :item="item"
      />

      <el-form-item style="display: none">
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
        <el-input></el-input>
      </el-form-item>
      <!-- slot 类型 -->
      <el-form-item
        v-if="item.fontendType == 'slot'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :style="{ visibility: item.hidden ? 'hidden' : 'visible' }"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>

        <slot :name="item.fieldName" />
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <!-- 详情details -->
      <el-form-item
        v-if="item.fontendType == 'details'"
        :key="index"
        class="details"
        :class="{
          textAlign: item.titleName == '经营范围',
        }"
        :label="item.titleName"
        :prop="item.fieldName"
      >
        <el-button
          v-if="item.valueType === 'textBtn'"
          type="primary"
          link
          color="#06B0B2"
          @click="handleDetailsBtn(item.fieldName)"
          >{{
            item.btnText ? item.btnText : formData[item.fieldName]
          }}</el-button
        >
        <span
          v-else-if="item.valueType === 'money' && formData[item.fieldName]"
          class="money"
        >
          ¥
          <span class="money_num">{{ formData[item.fieldName] }} </span>
          元</span
        >
        <span
          v-else-if="item.valueType === 'date'"
          style="padding-left: 12px"
          >{{ getYearMonthTime(formData[item.fieldName]) }}</span
        >
        <span v-else-if="item.valueType === 'time'" style="font-size: 12px">{{
          timestampToDate(formData[item.fieldName])
        }}</span>
        <span v-else style="font-size: 12px">
          <el-tooltip
            v-if="formData[item.fieldName]?.length > 15"
            :content="formData[item.fieldName]"
            popper-class="tooltip_text"
            effect="light"
            placement="top-start"
          >
            <span class="details_text">{{
              formData[item.fieldName].substring(0, 15) + "..."
            }}</span>
          </el-tooltip>
          <span v-else>{{ formData[item.fieldName] }}</span>
        </span>
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <!-- 详情Card -->
      <el-form-item
        v-if="item.fontendType == 'detailsCard'"
        :key="index"
        class="IDCard"
        :label="item.titleName"
        :prop="item.fieldName"
      >
        <div
          style="
            width: 100%;
            min-height: 100px;
            background-color: rgb(230, 247, 247);
            font-size: 12px;
            line-height: 18px;
            border-radius: 5px;
            padding: 10px;
            color: #999;
          "
          :style="{ marginLeft: item.marginLeft }"
        >
          <div
            class="IDCard_item"
            v-for="subItem in item.data"
            :key="subItem.label"
          >
            <span class="IDCard_item_label info">{{ subItem.label }}：</span>
            <span class="IDCard_item_value">{{ subItem.value }} </span>
          </div>
        </div>
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <!-- 详情IDCard -->
      <el-form-item
        v-if="item.fontendType == 'IDCard'"
        :key="index"
        class="IDCard"
        :label="item.titleName"
        :prop="item.fieldName"
      >
        <div
          style="
            width: 100%;
            min-height: 100px;
            background-color: rgb(230, 247, 247);
            font-size: 12px;
            line-height: 18px;
            border-radius: 5px;
            padding: 10px;
            color: #999;
          "
          :style="{ marginLeft: item.marginLeft }"
        >
          <div class="IDCard_item">
            <span class="IDCard_item_label">姓名:</span>
            <span class="IDCard_item_value">{{ item.data.idCardName }} </span>
          </div>
          <div class="IDCard_item">
            <span class="IDCard_item_label">性别:</span
            ><span class="IDCard_item_value">{{ item.data.sex }}</span>
          </div>
          <div class="IDCard_item">
            <span class="IDCard_item_label">出生日期</span
            ><span class="IDCard_item_value">{{ item.data.birthday }}</span>
          </div>
          <div class="IDCard_item">
            <span class="IDCard_item_label">身份证号</span
            ><span class="IDCard_item_value">{{ item.data.idCardNo }}</span>
          </div>
          <div class="IDCard_item">
            <span class="IDCard_item_label">省市区:</span
            ><span class="IDCard_item_value">{{
              item.data.provinceCityRegion
            }}</span>
          </div>
          <div class="IDCard_item">
            <span class="IDCard_item_label">住址:</span
            ><span class="IDCard_item_value">{{
              item.data.address ? item.data.address : "-"
            }}</span>
          </div>
          <!-- <div>手机号码：{{ item.data.phone }}</div> -->
        </div>
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <!-- @input="(val) => handelSearch(val, item.fieldName)" -->
      <!-- text 类型 -->
      <el-form-item
        v-if="item.fontendType == 'text'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :style="{ visibility: item.hidden ? 'hidden' : 'visible' }"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  type: item.isNumber ? 'number' : '',
                  message: '请输入数字',
                },
                {
                  min: item.min ? item.min : 0,
                  max: item.max ? item.max : 200,
                  message: '最大输入长度为' + item.max,
                  trigger: 'blur',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-input
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          :maxlength="item.maxLength"
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          :min="0"
          :type="item.type ? item.type : 'text'"
          :clearable="!item.disabled"
          @keyup.enter="handelSearch"
          @blur="(val) => handelBlur(val, item.fieldName)"
          @input="(val) => handelInput(val, item)"
        >
          <template #suffix v-if="item.unit">{{ item.unit }}</template>
          <template #suffix v-if="item.icon === 'search'">
            <div class="search_btn" @click="handelSearch">
              <el-icon :size="16"><Search /></el-icon>
            </div>
          </template>
        </el-input>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- autocomplete 类型 -->
      <el-form-item
        v-if="item.fontendType == 'autocomplete'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :style="{ visibility: item.hidden ? 'hidden' : 'visible' }"
        :rules="
          item.requireAble
            ? [
                {
                  required: true,
                  trigger: 'bulr',
                  message: item.titleName + '不能为空',
                },
              ]
            : []
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-autocomplete
          v-model.trim="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          :clearable="!item.disabled"
          :fetch-suggestions="
            (queryString, cb) => querySearch(queryString, cb, item)
          "
          @select="(val) => autocompleteSelect(val, item.fieldName)"
        >
          <template #default="{ item }">
            <div class="value">{{ item.label }}</div>
            <!-- <span class="link">{{ item.link }}</span> -->
          </template>
        </el-autocomplete>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- number 类型 -->
      <el-form-item
        v-if="item.fontendType == 'number'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-input
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          clearable
          type="number"
        />
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- textarea 类型 -->
      <el-form-item
        v-if="item.fontendType == 'textarea'"
        :key="index"
        class="textarea"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-input
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          type="textarea"
          :rows="item.rows ? item.rows : 1"
          resize="none"
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          clearable
        />
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- date 类型 -->
      <el-form-item
        v-if="item.fontendType == 'date'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-date-picker
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          range-separator="-"
          clearable
          :style="{ maxWidth: item.width ? item.width + '!important' : '100%' }"
          style="width: 100%"
          :disabled-date="item.disabledDateFn"
          :value-format="
            item.valueFormat
              ? item.valueFormat
              : item.type === 'datetimerange'
              ? null
              : 'YYYY-MM-DD'
          "
          align="right"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :type="item.type ? item.type : 'date'"
          placeholder="选择日期"
          @change="handelDateChange"
        />

        <div
          class="date_item"
          v-if="formData[item.fieldName] && item.isComputingTime"
        >
          共 {{ computingTime(formData[item.fieldName]) }} 天
        </div>
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- datetime -->
      <el-form-item
        v-if="item.fontendType == 'datetime'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-date-picker
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          style="width: 100%"
          clearable
          value-format="yyyy-MM-dd HH:mm:ss"
          align="right"
          type="datetime"
          placeholder="选择时间"
        />
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- month -->
      <el-form-item
        v-if="item.fontendType == 'month'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-date-picker
          v-model="formData[item.fieldName]"
          :disabled="item.disabled"
          style="width: 100%"
          clearable
          type="month"
          :placeholder="item.placeholder ? item.placeholder : '选择月份'"
          :value-format="item.valueFormat ? item.valueFormat : 'YYYY-MM'"
          :disabled-date="item.disabledDateFn"
        />
        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <!-- 主要使用区域 -->
      <!-- select 枚举类型 -->
      <el-form-item
        v-if="item.fontendType == 'select'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :style="{ visibility: item.hidden ? 'hidden' : 'visible' }"
        :rules="
          item.requireAble
            ? [
                {
                  required: true,
                  trigger: 'change',
                  message: item.titleName + '不能为空',
                },
              ]
            : []
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-select
          ref="selectRef"
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          style="width: 100%"
          clearable
          filterable
          placement="bottom-end"
          :placeholder="
            item.placeholder ? item.placeholder : '请选择' + item.titleName
          "
          @change="(val) => selectChange(val, item)"
        >
          <el-option
            v-for="opt in item.data"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
            style="font-size: 12px"
          >
          </el-option>
        </el-select>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- selectMultiple 枚举多选类型 -->
      <el-form-item
        v-if="item.fontendType == 'selectMultiple'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: ['blur', 'change'],
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-select
          v-model="formData[item.fieldName]"
          size="default"
          multiple
          filterable
          :disabled="item.disabled"
          style="width: 100%"
          clearable
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
        >
          <el-option
            v-for="opt in item.data"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
          >
            <span
              v-for="(label, labelInd) in item.label && item.label.split(',')"
              :key="labelInd"
            >
              {{ opt[label]
              }}{{ labelInd < item.label.split(",").length - 1 ? "-" : "" }}
            </span>
          </el-option>
        </el-select>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- selectValueFTA 类型 -->
      <el-form-item
        v-if="item.fontendType == 'selectValueFTA'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: ['blur', 'change'],
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-select
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          filterable
          style="width: 100%"
          clearable
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
        >
          <el-option
            v-for="opt in item.data"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
          >
            <span
              v-for="(label, labelInd) in item.label && item.label.split(',')"
              :key="labelInd"
            >
              {{ opt[label]
              }}{{ labelInd < item.label.split(",").length - 1 ? "-" : "" }}
            </span>
          </el-option>
        </el-select>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- selectValueMultipleFTA 类型 -->
      <el-form-item
        v-if="item.fontendType == 'selectValueMultipleFTA'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-select
          v-model="formData[item.fieldName]"
          size="default"
          multiple
          filterable
          :disabled="item.disabled"
          style="width: 100%"
          clearable
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
        >
          <el-option
            v-for="opt in item.data"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
          >
            <span
              v-for="(label, labelInd) in item.label && item.label.split(',')"
              :key="labelInd"
            >
              {{ opt[label]
              }}{{ labelInd < item.label.split(",").length - 1 ? "-" : "" }}
            </span>
          </el-option>
        </el-select>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- searchSelect 类型 -->
      <el-form-item
        v-if="item.fontendType == 'searchSelect'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-select
          v-model="formData[item.fieldName]"
          size="default"
          style="width: 100%"
          clearable
          filterable
          remote
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          :disabled="item.disabled"
          reserve-keyword
        >
          <el-option
            v-for="opt in item.data"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
          >
            <span
              v-for="(label, labelInd) in item.label && item.label.split(',')"
              :key="labelInd"
            >
              {{ opt[label]
              }}{{ labelInd < item.label.split(",").length - 1 ? "-" : "" }}
            </span>
          </el-option>
        </el-select>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- searchSelectmultiple  类型-->
      <el-form-item
        v-if="item.fontendType == 'searchSelectMultiple'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-select
          v-model="formData[item.fieldName]"
          size="default"
          style="width: 100%"
          clearable
          filterable
          multiple
          remote
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          :disabled="item.disabled"
          reserve-keyword
        >
          <el-option
            v-for="opt in item.data"
            :key="opt.value"
            :value="opt.value"
            :label="opt.label"
          >
            <span
              v-for="(label, labelInd) in item.label && item.label.split(',')"
              :key="labelInd"
            >
              {{ opt[label]
              }}{{ labelInd < item.label.split(",").length - 1 ? "-" : "" }}
            </span>
          </el-option>
        </el-select>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- selectTree 树结构下拉类型 -->
      <el-form-item
        v-if="item.fontendType == 'selectTree'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'change',
                  message: item.titleName + '不能为空',
                },
              ]
            : []
        "
      >
        <el-tree-select
          ref="selectTreeRef"
          v-model="formData[item.fieldName]"
          size="default"
          clearable
          filterable
          :data="item.data"
          :props="item.props"
          :check-strictly="true"
          :default-expand-all="true"
          :disabled="item.disabled"
          style="width: 100%"
          :placeholder="
            item.placeholder ? item.placeholder : '请选择' + item.titleName
          "
          @change="handelTreeChange"
          @node-click="handleNodeClick"
        />

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- selectTreeSerch 树结构下拉类型 -->
      <el-form-item
        v-if="item.fontendType == 'selectTreeSerch'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'change',
                  message: item.titleName + '不能为空',
                },
              ]
            : []
        "
      >
        <el-tree-select
          ref="selectTreeRef"
          v-model="formData[item.fieldName]"
          size="default"
          clearable
          filterable
          :filter-method="(val) => filterMethod(val, item.fieldName, index)"
          :data="item.data"
          :props="item.props"
          :check-strictly="true"
          :default-expand-all="true"
          :disabled="item.disabled"
          style="width: 100%"
          :placeholder="
            item.placeholder ? item.placeholder : '请选择' + item.titleName
          "
          @change="handelTreeChange"
        />

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- inputSelectTree 树结构下拉类型 -->
      <el-form-item
        v-if="item.fontendType == 'inputSelectTree'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: true,
                  trigger: 'change',
                  message: item.titleName + '不能为空',
                },
              ]
            : []
        "
      >
        <el-popover ref="" placement="" trigger="click">
          <template #reference>
            <el-input
              v-model="scope.row[item.prop]"
              :suffix-icon="ArrowDown"
              class="focus"
              @input="(val) => filterChange(val, item.prop, scope.$index)"
              @focus="handleFocus"
            ></el-input>
          </template>
          <el-tree :data="item.data" :props="item.props" />
        </el-popover>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- selectTreeMultiple 树结构下拉多选类型 -->
      <el-form-item
        v-if="item.fontendType == 'selectTreeMultiple'"
        class="selectTree"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <el-tree-select
          class="selectTree"
          v-model="formData[item.fieldName]"
          ref="treeRef"
          size="default"
          :data="item.data"
          :props="item.props"
          clearable
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          multiple
          collapse-tags
          collapse-tags-tooltip
          :max-collapse-tags="2"
          :default-expand-all="false"
          :render-after-expand="false"
          show-checkbox
          :check-strictly="item.isCheckStrictly"
          style="width: 100%"
          @visible-change="(visible) => handleVisibleChange(visible, item)"
        />

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- cascader 级联下拉类型 -->
      <el-form-item
        v-if="item.fontendType == 'cascader'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-cascader
          ref="cascader"
          v-model="formData[item.fieldName]"
          size="default"
          clearable
          :disabled="item.disabled"
          style="width: 100%"
          :options="item.data"
          :props="item.props"
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          filterable
          @change="(val) => cascaderSelectChange(val, item.fieldName)"
        />

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- cascaderMultiple 级联下拉多选类型 -->
      <el-form-item
        v-if="item.fontendType == 'cascaderMultiple'"
        :key="index"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                  validator: validateEveryData,
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-cascader
          v-model="formData[item.fieldName]"
          size="default"
          :disabled="item.disabled"
          style="width: 100%"
          :options="item.data"
          :placeholder="
            item.placeholder ? item.placeholder : '请输入' + item.titleName
          "
          filterable
        />

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- radioBtn 类型 -->
      <el-form-item
        v-if="item.fontendType == 'radioBtn'"
        :key="index"
        class="switch_item"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: true,
                  trigger: 'change',
                  message: item.titleName + '不能为空',
                },
              ]
            : []
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-radio-group
          v-model="formData[item.fieldName]"
          :disabled="item.disabled"
          size="default"
          style="width: 100%; border-radius: 16px 0px 0px 16px"
          @change="(val) => handelRadioChange(val, item.titleName)"
        >
          <el-radio-button
            v-for="(radio, radioIn) in item.data"
            style="width: 50%"
            :key="radioIn"
            size="default"
            :disabled="item.disabled"
            :label="radio.label"
            :value="radio.value"
          >
          </el-radio-button>
        </el-radio-group>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- radio 类型 -->
      <el-form-item
        v-if="item.fontendType == 'radio'"
        :key="index"
        class="switch_item"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              <i style="margin-right: 2px" />
              {{ item.titleName }}
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <el-radio-group v-model="formData[item.fieldName]">
          <el-radio
            v-for="(radio, radioIn) in item.data"
            :key="radioIn"
            :value="radio.value"
            @change="handelRadioChange"
            >{{ radio.label }}</el-radio
          >
        </el-radio-group>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- switch 类型 -->
      <el-form-item
        v-if="item.fontendType == 'switch'"
        :key="index"
        class="switch_item"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <template #label>
          <el-tooltip
            v-if="item.remark"
            class="tooltip_item"
            effect="dark"
            :content="item.remark"
            placement="top-start"
          >
            <span>
              {{ item.titleName }}
              <i style="margin-left: 5px" />
            </span>
          </el-tooltip>
          <span v-else>{{ item.titleName }}</span>
        </template>
        <div style="width: 100%">
          <el-switch
            v-model="formData[item.fieldName]"
            size="default"
            :active-value="1"
            :inactive-value="0"
            active-color="#06B0B2"
            inactive-color="#f2f2f2"
          />
        </div>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>
      <!-- uploadImg类型 -->
      <el-form-item
        v-if="item.fontendType == 'uploadImg'"
        :key="index"
        class="upload_item"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <el-upload
          class="uploadDisabled"
          :disabled="item.disabled"
          v-model:file-list="formData[item.fieldName]"
          action="#"
          list-type="picture-card"
          :accept="item.accept"
          :limit="item.limit"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemoveLicense"
          :before-upload="setListForm"
          :auto-upload="false"
          style="width: 80px; height: 80px"
          :on-change="(param) => handleImgChange(param, item)"
        >
          <el-icon>
            <Plus />
          </el-icon>
        </el-upload>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <!-- uploadFile类型 -->
      <el-form-item
        v-if="item.fontendType == 'uploadFile'"
        :key="index"
        class="upload_file"
        :label="item.titleName"
        :prop="item.fieldName"
        :rules="
          item.requireAble
            ? [
                {
                  required: item.requireAble || false,
                  trigger: 'blur',
                  key: item.titleName,
                  fontendType: item.fontendType,
                  value: formData[item.fieldName],
                  validator: validateEveryData,
                  message: item.titleName + '不能为空',
                },
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
            : [
                {
                  pattern: item.reg ? new RegExp(item.reg) : '',
                  message: item.regMsg ? item.regMsg : '',
                  validator: validateEveryData,
                },
              ]
        "
      >
        <div class="upload_box">
          <el-upload
            :disabled="item.disabled"
            v-model:file-list="formData[item.fieldName]"
            action=""
            :http-request="uploadFile"
            :accept="item.accept"
            :limit="item.limit"
            :on-exceed="handleExceed"
            :on-preview="handleFilePreview"
            :before-upload="setListForm"
            :on-change="(param) => handleChange(param, item.fieldName)"
          >
            <el-button type="primary" link>点击上传</el-button>
          </el-upload>
        </div>

        <el-tooltip
          v-if="item.tooltip"
          :content="item.tooltip"
          popper-class="tooltip_text"
          effect="light"
          placement="top-start"
        >
          <el-icon :size="16" color="#ccc"><Warning /></el-icon>
        </el-tooltip>
        <el-image
          v-if="item.isAdd"
          class="addImage"
          :src="require('../../assets/image/road2.png')"
          @click="handleAdd(item.fieldName)"
        ></el-image>
      </el-form-item>

      <el-form-item
        v-if="item.fontendType == 'uploadIDCardFont'"
        :label="item.titleName"
        :prop="item.fieldName"
        class="uploadIdCard"
      >
        <el-upload
          ref="uploadRefPo"
          v-model:file-list="formData[item.fieldName]"
          class="upload-box"
          :class="{ uploadBox_hide: formData[item.fieldName].length }"
          action="#"
          list-type="picture-card"
          :limit="1"
          :auto-upload="false"
          :before-upload="(file) => beforeUpload(file)"
          :on-change="(param) => handleIdCardChange(param, item.fieldName)"
          :on-preview="handlePictureCardPreview"
        >
          <el-icon><Plus /></el-icon>
          <div class="el-upload__text">
            <div class="upload-green-text">上传身份证</div>
          </div>
          <svg
            class="up_bgi"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="92.2727279663086"
            height="101.06060791015625"
            viewBox="0 0 92.2727279663086 101.06060791015625"
          >
            <g>
              <path
                d="M46.3337,64.1058C56.2926,64.1058,69.3763,48.1978,69.3763,28.074C69.3763,7.95395,60.9798,0,46.3337,0C31.6875,0,22.9004,7.72001,22.9004,28.074C22.9004,48.0576,36.3748,64.1058,46.3337,64.1058ZM91.8838,90.3668C90.7676,86.5757,89.43,82.8398,88.1276,81.1793C88.127,81.1785,88.1268,81.1781,88.1262,81.1774C85.7037,78.0961,71.8078,69.2622,65.6625,65.4352C65.6613,65.4344,65.6606,65.434,65.6594,65.4332C64.0424,64.426,62.0499,65.1596,61.1839,67.0808C61.1831,67.0826,61.1824,67.0841,61.1816,67.0859L54.1643,82.6394C53.1741,84.8333,50.4192,84.2315,50.1385,81.7602C50.1383,81.759,50.1383,81.7584,50.1381,81.7572C50.079,81.2287,50.1576,80.6913,50.3634,80.2186C50.3641,80.217,50.3646,80.2157,50.3653,80.2142L51.9136,76.6735C51.9141,76.6722,51.9146,76.6712,51.9151,76.6699C52.3204,75.7435,52.2174,74.6153,51.6545,73.8157L48.6865,69.6001C47.3515,67.7029,44.912,67.7038,43.5779,69.602L40.6177,73.8138C40.0551,74.6147,39.9523,75.7434,40.3574,76.6708C40.3577,76.6714,40.3579,76.6718,40.3581,76.6724L41.9074,80.2137C41.9077,80.2144,41.9078,80.2148,41.9081,80.2154C42.1149,80.6879,42.1939,81.2257,42.1343,81.7544C42.1341,81.7562,42.1339,81.7575,42.1337,81.7593C41.8537,84.2298,39.098,84.8312,38.1079,82.6376L31.0905,67.0841C31.0897,67.0823,31.089,67.0808,31.0882,67.079C30.2222,65.1578,28.2298,64.4242,26.6127,65.4314C26.6115,65.4321,26.6108,65.4326,26.6096,65.4333C20.4638,69.2608,6.56553,78.0942,4.14521,81.1765C2.84119,82.8366,1.50329,86.573,0.388399,90.365C-1.14792,95.5847,2.06303,101.061,6.6826,101.061L85.5826,101.061C85.5841,101.061,85.5854,101.061,85.5869,101.061C90.2101,101.063,93.4214,95.5858,91.8838,90.3668Z"
                fill="#EEEEEE"
                fill-opacity="1"
              />
            </g>
          </svg>
        </el-upload>
      </el-form-item>

      <el-form-item
        v-if="item.fontendType == 'uploadIDCardBack'"
        :label="item.titleName"
        :prop="item.fieldName"
        class="uploadIdCard"
      >
        <el-upload
          v-model:file-list="formData[item.fieldName]"
          class="upload-box-re"
          :class="{ uploadBox_hide: formData[item.fieldName].length }"
          ref="uploadRefRe"
          list-type="picture-card"
          action="#"
          :limit="1"
          :auto-upload="false"
          :before-upload="(file) => beforeUpload(file)"
          :on-change="(param) => handleIdCardChange(param, item.fieldName)"
          :on-preview="handlePictureCardPreview"
        >
          <el-icon><Plus /></el-icon>
          <div class="el-upload__text">
            <div class="upload-green-text">上传身份证</div>
          </div>
          <img class="up_bgi_re" src="@/assets/image/reverse.png" alt="" />
        </el-upload>
      </el-form-item>
    </template>
    <!-- 图片预览 -->
    <el-dialog v-model="uploadShow">
      <img
        style="width: 100%"
        w-full
        :src="dialogImageUrl"
        alt="Preview Image"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { timestampToDate, getYearMonthTime } from "@/uitls/global";
import { imgUpLoadApi, discernCodeApi } from "@/api/base";
import { ElMessage } from "element-plus";
import { ref, defineProps, defineEmits, onMounted } from "vue";
import { Plus, Search, Warning } from "@element-plus/icons-vue";

const emits = defineEmits([
  "handelUploadFile",
  "handelSearch",
  "handleDetailsBtn",
  "selectChange",
  "handelRadioChange",
  "handelTreeChange",
  "handelDateChange",
  "filterMethod",
  "handleNodeClick",
  "cascaderSelectChange",
  "handleAdd",
  "handelBlur",
]);
const props = defineProps({
  grid: {
    type: Number,
    default: 2,
  },

  /**
     * formTitles: [
        {
          titleName: "",
          fieldName: "",
          fontendType: "",
          data: [],
        }
  */

  formTitles: {
    type: Array,
    default: () => [],
  },
  formData: {
    type: Object,
  },
  formHeight: String,
  currentRow: Object,
});
const selectRef = ref(null);
const cascader = ref(null);
const uploadShow = ref(false);
const dialogImageUrl = ref("");
const treeRef = ref(null);
const selectTreeRef = ref(null);
// 存储选中的节点 id 和父级 id
const selectedInfo = ref({
  ids: [],
  parentIds: [],
});

onMounted(() => {
  if (props.formTitles) {
    props.formTitles.forEach((item) => {
      if (item.fieldName === "menuIds") {
        if (item.data.length > 0) {
          item.isCheckStrictly = true;
          setTimeout(() => {
            item.isCheckStrictly = false;
          }, 500);
        }
      }
    });
  }
});

const handelInput = (val, item) => {
  if (item.type == "number") {
    if (+val < 0) {
      ElMessage.error("请输入大于的数字");
      props.formData[item.fieldName] = 1;
    }
  }
};

const handelBlur = (val, name) => {
  emits("handelBlur", val, name);
};

const handleAdd = (name) => {
  emits("handleAdd", name);
};

const cascaderSelectChange = (val, name) => {
  emits("cascaderSelectChange", cascader.value, name, val);
};

const filterMethod = (value, name, index) => {
  emits("filterMethod", value, name, index);
};

const autocompleteSelect = (val, prop) => {
  props.formData[prop] = val.label;
};

const querySearch = (queryString, cb, feild) => {
  const data = props.formTitles.find(
    (item) => item.fieldName === feild.fieldName
  ).data;
  const result = queryString
    ? data.filter((item) => item.label.includes(queryString))
    : data;
  cb(result);
};

const handelDateChange = (val) => {
  emits("handelDateChange", val);
};

const computingTime = (time) => {
  if (!time) return "";
  const start = new Date(time[0]);
  const end = new Date(time[1]);
  const days = Math.ceil((end - start) / (24 * 3600 * 1000)) + 1;
  return days;
};

const handleNodeClick = (data, node, index) => {
  emits("handleNodeClick", node.parent, node);
};
const handelTreeChange = (val) => {
  emits("handelTreeChange", val, selectTreeRef.value, props.formData);
};

// 上传身份证图片
const handleIdCardChange = async (param, val) => {
  const fileFormData = new FormData();
  fileFormData.append("multipartFile", param.raw);
  const res = await imgUpLoadApi(fileFormData);
  if (res.status === 200) {
    const url = res.data.filePath;
    dialogImageUrl.value = url;
    props.formData[val] = [{ url, name: param.name }];
  }
};

// 上传图片
const handleImgChange = async (param, item) => {
  const fileFormData = new FormData();
  fileFormData.append("multipartFile", param.raw);
  const res = await imgUpLoadApi(fileFormData);
  if (res.status === 200) {
    const url = res.data.filePath;
    dialogImageUrl.value = url;
    props.formData.fileUrls = [];
    if (item.limit === 1) {
      props.formData[item.fieldName] = [param];
      props.formData.fileUrls = [{ url, name: param.name }];
    } else {
      props.formData.fileUrls.push({ url, name: param.name });
    }
  }
};

// 上传
const handleChange = async (param, val) => {
  const fileFormData = new FormData();
  fileFormData.append("multipartFile", param.raw);
  const res = await imgUpLoadApi(fileFormData);
  if (res.status === 200) {
    const url = res.data.filePath;
    dialogImageUrl.value = url;
    // props.formData[val] = [{ url, name: param.name }];
    props.formData.fileUrl = url;
    props.formData.jobDescription = param.name;
  }
};

const selectChange = (val, item) => {
  emits("selectChange", val, item, props.formData);
};

const handelRadioChange = (val, label) => {
  emits("handelRadioChange", val, label);
};

const handleVisibleChange = (val, item) => {
  if (val) {
    item.isCheckStrictly = false;
  }
};

// // 处理选中节点变化的方法
// const handelCheck = (checkedNodes, item) => {
//   item.isCheckStrictly = false;
// };

const handleExceed = (files, fileList) => {
  ElMessage.error("上传文件数量不能超过" + 1 + "个");
  // fileList.splice(0, fileList.length);
};

const handleDetailsBtn = (name) => {
  emits("handleDetailsBtn", name);
};

const handelSearch = (val, name) => {
  emits("handelSearch", props.formData, name);
};

// 上传文件
const uploadFile = (param) => {
  const fileFormData = new FormData();
  fileFormData.append("multipartFile", param.file);
  emits("handelUploadFile", fileFormData);
};

// 图片预览
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url;
  uploadShow.value = true;
};

// 文件预览
const handleFilePreview = (file) => {
  window.open(file.url);
};

// 删除图片
const handleRemoveLicense = (file, fileList) => {
  fileList.splice(fileList.indexOf(file), 1);
  props.formData[props.fieldName] = fileList;
};

// 图片上传限制
const setListForm = (file, fileList) => {
  const is60M = file.size / 1024 / 1024 < 10;
  if (!is60M) {
    ElMessage.error("上传文件大小不能超过" + 10 + "MB");
    return false;
  }
  return true;
};

// 表单验证
const validateEveryData = (rule, value, callback) => {
  if (rule.required) {
    if (Object.prototype.toString.call(value) === "[object Undefined]") {
      callback(new Error(rule.message));
    } else if (Object.prototype.toString.call(value) === "[object Array]") {
      if (value.length === 0 || !value) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else if (Object.prototype.toString.call(value) === "[object Number]") {
      if (!value) {
        if (value + "" !== "0") {
          callback(new Error(rule.message));
        } else {
          callback();
        }
      } else {
        callback();
      }
    } else if (Object.prototype.toString.call(value) === "[object String]") {
      if (!value) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    } else {
      callback();
    }
  } else if (value !== undefined && rule.pattern) {
    if (!rule.pattern.test(value)) {
      callback(new Error(rule.message));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
</script>

<style lang="scss" scoped>
.form-item-box {
  box-sizing: border-box;
  display: grid;
  flex-wrap: wrap;
  grid-gap: 12px;
  align-content: start;
  align-items: start;
  width: 100%;
  padding: 12px 20px;
  overflow: auto;

  :deep(.el-form-item) {
    margin-bottom: 6px;
  }

  .search_btn {
    cursor: pointer;
  }

  .details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    gap: 8px;
    margin-bottom: 0px;

    :deep(.el-form-item__label) {
      height: 20px;
      line-height: 20px;
      flex: 1;
    }

    :deep(.el-form-item__content) {
      flex: 3;
      display: block;
      text-align: right;
    }
  }
  .textAlign {
    :deep(.el-form-item__content) {
      text-align: left;
    }
  }
}
:deep(.el-radio-button__inner) {
  width: 100% !important;
  font-size: 12px;
}

:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: #06b0b2;
  border-color: #06b0b2;
}

.upload_item {
  :deep(.el-upload--picture-card) {
    width: 80px !important;
    height: 80px !important;
  }

  :deep(.el-upload-list--picture-card .el-upload-list__item-actions) {
    width: 80px !important;
    height: 80px !important;
  }
  :deep(.el-upload-list--picture-card .el-upload-list__item-thumbnail) {
    width: 80px !important;
    height: 80px !important;
  }
  :deep(.el-upload-list__item.is-ready) {
    width: 80px !important;
    height: 80px !important;
  }
  :deep(.el-upload-list--picture-card) {
    flex-wrap: nowrap;
  }
  :deep(.el-upload-list__item.is-success) {
    padding-left: 0;
    padding-right: 30px;
    height: 80px;
    width: 80px;
  }
}

:deep(.el-select__placeholder.is-transparent) {
  font-size: 12px !important;
}

:deep(.el-select__wrapper.is-focused) {
  box-shadow: 0 0 0 1px #06b0b2 inset !important;
}

:deep(.el-select-dropdown__item.is-selected) {
  color: #06b0b2 !important;
}

.upload_file {
  margin-bottom: 0 !important;
  .upload_box {
    width: 100%;
    text-align: right;
  }
}

:deep(.el-tag__close) {
  color: #06b0b2 !important;

  &:hover {
    background-color: rgba(6, 176, 178, 0.1) !important;
  }
}

.IDCard_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .IDCard_item_label {
    flex: 1;
    text-align: right;
    margin-right: 15px;
  }
  .info {
    flex: 1.5;
  }
  .IDCard_item_value {
    flex: 3;
  }
}

:deep(.el-form-item__content) {
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;

  .el-icon {
    margin-left: 8px;
  }
}

:deep(.el-form-item.upload) {
  flex-direction: column;
  .el-form-item__content {
    justify-content: center;
    padding-left: 82px;
  }
}

.uploadIdCard {
  :deep(.el-upload--picture-card) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 168px;
  }

  :deep(.el-upload-list--picture-card .el-upload-list__item) {
    width: 265px;
    height: 168px;
    margin: 0;
  }

  :deep(.el-upload-list--picture-card .el-upload-list__item-actions) {
    width: 265px;
    height: 168px;
    margin: 0;
  }

  :deep(.el-upload-list__item.is-success) {
    padding-left: 0;
    padding-right: 30px;
    width: 280px;
  }
}

.upload-box,
.upload-box-re {
  position: relative;
  width: 265px;
  height: 168px;

  .up_bgi {
    position: absolute;
    right: 10px;
  }

  .up_bgi_re {
    position: absolute;
    left: 15px;
    top: 15px;
  }
}

.uploadBox_hide {
  :deep(.el-upload--picture-card) {
    display: none !important;
  }
}

:deep(.el-radio-button:first-child .el-radio-button__inner) {
  border-radius: 16px 0px 0px 16px;
  &:hover {
    color: #06b0b2;
  }
}
:deep(.el-radio-button:last-child .el-radio-button__inner) {
  border-radius: 0px 16px 16px 0px;
  &:hover {
    color: #06b0b2;
  }
}
:deep(.el-radio-button.is-active:first-child .el-radio-button__inner) {
  border-radius: 16px 0px 0px 16px;
  &:hover {
    color: #fff;
  }
}
:deep(.el-radio-button.is-active:last-child .el-radio-button__inner) {
  border-radius: 0px 16px 16px 0px;
  &:hover {
    color: #fff;
  }
}

.money {
  font-size: 12px;
  color: #999999;
  padding-left: 12px;

  .money_num {
    font-weight: 400;
    font-size: 18px;
    color: #333333;
  }
}

:deep(.el-form-item__label) {
  font-size: 12px !important;
  color: #666666;
}

.date_item {
  font-size: 12px;
  color: #333333;
  text-align: right;
  width: fit-content;
  min-width: 70px;
  padding: 0 8px;
}

:deep(.el-range-editor.el-input__wrapper) {
  padding: 0px 2px;

  .el-range-input,
  .el-range-separator {
    font-size: 12px;
  }
}

.addImage {
  width: 16px;
  line-height: 0px;
  margin-left: 8px;
  cursor: pointer;
}

.details_text {
  // 文本超出不换行，显示省略号
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

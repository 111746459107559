<template>
  <div class="record">
    <span class="margin"
      >Copyright © 2001- 2024 ©上海小凡小可网络科技有限公司</span
    >
    <span class="margin pointer" @click="toBanck">沪ICP备2023016443号-1</span>
  </div>
</template>
<script setup>
import { ref } from "vue";

const toBanck = () => {
  console.log(11112);

  window.open("https://beian.miit.gov.cn/", "_blank");
};
</script>
<style lang="scss" scoped>
.record {
  font-size: 10px;
  color: #999;
  text-align: center;
}

.margin {
  margin-right: 20px;
}

.pointer {
  &:hover {
    color: #333;
    cursor: pointer;
  }
}
</style>
